import React, { useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import styles from './TransakProviderConnector.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { SyncLoader } from 'react-spinners'
import { inject } from 'mobx-react'
import WidgetLayout from '@components/widget/layout/WidgetLayout'
import IframeWrapper from '@components/common/iframe-wrapper/IframeWrapper'
import providers from '@constants/providers'

const TransakProviderConnector = (props) => {
    const { config, logger, className, onCancel, rampInfo } = props
    const { rampType, source, sourceAmount, destination, destinationAmount, withdrawalMethod, paymentMethod } = rampInfo
    const widgetLayoutRef = useRef(null)
    const iframeId = 'transak-iframe'
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [, forceUpdate] = useState()

    const generateTransakUrl = () => {
        const transakHost = (config.app.isProduction) ? 'https://global.transak.com' : 'https://global-stg.transak.com'
        const apiKey = config.service.transak.toUseApiKey ? config.service.transak.apiKey : null
        const product = rampType.toLowerCase()

        let transakUrl = `${transakHost}/?productsAvailed=${product.toUpperCase()}&cryptoCurrencyCode=${source}&cryptoAmount=${sourceAmount}&fiatCurrency=${destination}&paymentMethod=${withdrawalMethod}`

        if (rampType === 'BUY') {
            transakUrl = `${transakHost}/?productsAvailed=${product.toUpperCase()}&cryptoCurrencyCode=${destination}&fiatAmount=${sourceAmount}&fiatCurrency=${source}&paymentMethod=${paymentMethod}`
        }

        if (apiKey) {
            transakUrl += `&apiKey=${apiKey}`
        }

        return transakUrl
    }

    const transakUrl = generateTransakUrl()

    useEffect(() => {
        forceUpdate(Math.random())
    }, [widgetLayoutRef])

    const handleIframeLoad = () => {
        setLoading(false)
        setIsReady(true)
    }

    return (
        <WidgetLayout
            ref={widgetLayoutRef}
            className={cn(styles.wrapper, className)}
            contentWrapperClassName={styles.widgetContentWrapper}
            title='Transak'
            toShowHeader={false}
        >
            {error &&
            <div className={styles.errorWrapper}>
                <div className='flex items-center gap-3'>
                    <img
                        src='/images/logo/avenir.svg'
                        alt='Avenir'
                    />

                    <FontAwesomeIcon icon={faCircleExclamation} className='text-[20px]' color='red' />

                    <img
                        src={providers.transak.logoUrl}
                        alt='Transak'
                    />
                </div>

                <div className='font-semibold text-[16px] flex items-center gap-3'>
                    Unable to connect to Transak
                </div>
            </div>}

            {!error &&
            <>
                {loading &&
                <div className={styles.loaderWrapper}>
                    <div className='flex items-center gap-3'>
                        <img
                            src='/images/logo/avenir.svg'
                            alt='Avenir'
                        />

                        <FontAwesomeIcon icon={faAnglesRight} className='text-[20px]' />

                        <img
                            src={providers.transak.logoUrl}
                            alt='Transak'
                        />
                    </div>

                    <div className='font-semibold text-[16px] flex items-center gap-3'>
                        Connecting you to Transak
                        <SyncLoader
                            size={4}
                            color='#36b3d0'
                        />
                    </div>

                    <div className='flex items-center gap-3'>
                        {sourceAmount} {source}
                        <FontAwesomeIcon icon={faArrowRight} className='text-[10px]' />
                        {destinationAmount} {destination}
                    </div>
                </div>}

                <IframeWrapper
                    className={cn(styles.iframeWrapper, { [styles.show]: isReady})}
                    title='Transak'
                    widgetLayoutRef={widgetLayoutRef}
                    cancelDrawerProps={{
                        getContainer: widgetLayoutRef.current,
                        onCancel,
                        style: {
                            position: 'absolute'
                        }
                    }}
                >
                    <iframe
                        id={iframeId}
                        src={transakUrl}
                        allow='camera;microphone;fullscreen;payment'
                        onLoad={handleIframeLoad}
                    />
                </IframeWrapper>
            </>}
        </WidgetLayout>
    )
}

export default inject('config', 'logger')(TransakProviderConnector)
