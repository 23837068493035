import React, { useState, useEffect, ef, useContext } from 'react'
import cn from 'classnames'
import Header from './header/Header'
import ContentWrapper from './content-wrapper/ContentWrapper'
import styles from './Layout.module.scss'
import AppContext from '@contexts/app/AppContext'

const Layout = React.forwardRef((props, ref) => {
    const { toShowBackgroundImage } = props
    const { setLayoutRef } = useContext(AppContext)

    useEffect(() => {
        setLayoutRef(ref)
    }, [])

    return (
        <div className={styles.wrapper} ref={ref}>
            <Header />

            <ContentWrapper toShowBackgroundImage={toShowBackgroundImage} >
                {props.children}
            </ContentWrapper>
        </div>
    )
})

Layout.defaultProps = {
    showHeader: true,
    toShowBackgroundImage: false
}

export default Layout
