import React, { useContext, useEffect, useImperativeHandle, useState } from 'react'
import cn from 'classnames'
import styles from './ProviderSelectInput.module.scss'
import SellAssetProviderOptionDrawer from './option/drawer/ProviderSelectOptionDrawer'
import { inject } from 'mobx-react'
import AppContext from '@contexts/app/AppContext'

const ProviderSelectInput = React.forwardRef((props, ref) => {
    const { logger, onClick, className, widgetLayoutRef, optionTitle, options, placeholder, value, onChange } = props
    const [toShowDrawer, setToShowDrawer] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [selectedOption, setSelectedOption] = useState(value)
    const { layoutRef } = useContext(AppContext)

    useImperativeHandle(ref, () => ({
        click: (e) => {
            handleClick(e)
        }
    }))

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (value) {
            const index = options.findIndex(option => option.value === value)
            setSelectedIndex(index)
            if (value && options.length === 0) {
                setSelectedOption(value)
            } else {
                setSelectedOption(options[index])
            }
        } else {
            setSelectedIndex(-1)
            setSelectedOption(null)
        }
    }, [options, value])

    const handleClick = (e) => {
        onClick(e)
        setToShowDrawer(true)
    }

    const closeDrawer = () => {
        setToShowDrawer(false)
    }

    return (
        <>
            <button
                ref={ref}
                type='button'
                className={cn(styles.wrapper, className)}
                onClick={handleClick}
            >
                {!selectedOption &&
                <div>{placeholder}</div>}

                {selectedOption &&
                <>
                   By <img className={styles.logo} src={selectedOption?.logoUrl} /> <span className='font-semibold'>{selectedOption?.name}</span>
                </>}
            </button>

            <SellAssetProviderOptionDrawer
                title={optionTitle}
                open={toShowDrawer}
                options={options}
                selectedIndex={selectedIndex}
                onClose={closeDrawer}
                getContainer={layoutRef?.current}
                style={{
                    position: 'absolute'
                }}
                onChange={(provider) => {
                    onChange(provider.value)
                    setToShowDrawer(false)
                }}
            />
        </>
    )
})

export default inject('logger')(ProviderSelectInput)
