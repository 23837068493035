import React, { useContext, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce'
import styles from './AssetSelectInput.module.scss'
import AssetSelectOptionDrawer from './option/drawer/AssetSelectOptionDrawer'
import { inject } from 'mobx-react'
import AppContext from '@contexts/app/AppContext'

const AssetSelectInput = React.forwardRef((props, ref) => {
    const { logger, className, onClick, widgetLayoutRef, optionTitle, loadOptions, toAsyncLoad, toReturnOptionAsValue, placeholder, value, onChange } = props
    const [toShowDrawer, setToShowDrawer] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [selectedOption, setSelectedOption] = useState(value)
    const [text, setText] = useState('')
    const [options, setOptions] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(toAsyncLoad)
    const { layoutRef } = useContext(AppContext)

    useEffect(() => {
        if (!toAsyncLoad) {
            setOptions(props.options)
        }
    }, [props.options])

    useEffect(() => {
        if (value) {
            const temp = toAsyncLoad ? value.value : value
            const index = options.findIndex(option => option.value === temp)
            setSelectedIndex(index)
            if (value && options.length === 0) {
                setSelectedOption(value)
            } else {
                setSelectedOption(options[index])
            }
        } else {
            setSelectedIndex(-1)
            setSelectedOption(null)
        }
    }, [options, value])

    useEffect(() => {
        if (toShowDrawer && hasMore && toAsyncLoad) {
            load()
        }

        if (!toShowDrawer && toAsyncLoad) {
            // Set delay to prevent flickering
            setTimeout(() => {
                setText(null)
                setOptions([])
                setHasMore(true)
            }, 500)
        }
    }, [toShowDrawer])

    useEffect(() => {
        if (toShowDrawer) {
            if (toAsyncLoad) {
                debounceLoad(true)
            } else {
                if (!text) {
                    setOptions(props.options)
                } else {
                    const temp = options.filter(option => {
                        return option.desc?.toLowerCase().includes(text?.toLowerCase()) ||
                            option.value?.toLowerCase().includes(text?.toLowerCase())
                    })
    
                    setOptions(temp)
                }
            }
        }
        
    }, [text])

    const load = async (toClear = false) => {
        try {
            setLoading(true)
            const data = await loadOptions(text, (toClear) ? [] : options)
            await new Promise(resolve => setTimeout(resolve, 500))
            if (toClear) {
                setOptions(data.options)
            } else {
                setOptions([...options, ...data.options])
            }
            setHasMore(data.hasMore)
        } catch (error) {
            logger.error('[Asset Select Input Load Option Error]', error)
        } finally {
            setLoading(false)
        }
    }

    const debounceLoad = useDebouncedCallback(load, 500)

    const handleScrollBottom = () => {
        if (hasMore && toAsyncLoad) {
            load()
        }
    }

    const handleClick = (e) => {
        onClick(e)
        setToShowDrawer(true)
    }

    const closeDrawer = () => {
        setToShowDrawer(false)
    }

    return (
        <>
            <button
                type='button'
                className={cn(styles.wrapper, className)}
                onClick={handleClick}
            >
                {!selectedOption &&
                <div>{placeholder}</div>}

                {selectedOption &&
                <>
                    {selectedOption?.logoUrl &&
                    <img className={styles.logo} src={selectedOption?.logoUrl} />}

                    {!selectedOption.logoUrl && <div className={styles.logoPlaceholder} />}

                    <span>
                        {selectedOption?.name}
                    </span>
                </>}

                <FontAwesomeIcon icon={faAngleDown} />
            </button>

            <AssetSelectOptionDrawer
                title={optionTitle}
                open={toShowDrawer}
                loading={loading}
                options={options}
                selectedIndex={selectedIndex}
                onTextChange={(e) => setText(e.target.value)}
                onClose={closeDrawer}
                getContainer={layoutRef?.current}
                style={{
                    position: 'absolute'
                }}
                onScrollBottom={handleScrollBottom}
                onChange={(asset) => {
                    if (toReturnOptionAsValue) {
                        onChange(asset)
                    } else {
                        onChange(asset.value)
                    }
                    setToShowDrawer(false)
                }}
            />
        </>
    )
})

AssetSelectInput.defaultProps = {
    placeholder: 'Select asset'
}

export default inject('logger')(AssetSelectInput)
